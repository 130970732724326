import React from "react";
import {NavLink} from "react-router-dom";
import CloseSidebar from "../../Main/CloseSidebar";

const TheoryInfo = (props) => {
    CloseSidebar();

    return (
        <div className="card m-3" >
            <div className="card-body">
                <div className="text-center text-bold mb-4">Теория online</div>
                <div className="theoryinfo">
                    { (props.auth.school_id && props.auth.school_id !== 0 && props.auth.type !== 'pdd') ? (
                        <NavLink to="/theory/videos" className="btn btn-primary text-bold w-100">Смотреть видео уроки</NavLink>
                    ) : (
                        <NavLink to="/videos" className="btn btn-primary text-bold w-100">Смотреть видео уроки</NavLink>
                    ) }
                    <div className="text-center m-4 mb-2">
                        { (props.auth.school_id && props.auth.school_id !== 0 && props.auth.type !== 'pdd') ? '' : (
                            <NavLink to="/questions" className="text-bold w-100">Решать билеты ПДД</NavLink>
                        ) }
                    </div>
                    { (!props.auth.school_id || props.auth.school_id === 0) ? (
                        <div className="text-center m-4">
                            <NavLink to="/getapp" className="text-bold mt-3">Записаться в автошколу</NavLink>
                        </div>
                    ) : ('') }
                </div>
            </div>
        </div>
    )
}

export default TheoryInfo;
