import React, { useState, useEffect } from "react";
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import CloseSidebar from "../../Main/CloseSidebar";

function History(props) {
    CloseSidebar();

    const API_SCRIPT = process.env.REACT_APP_API_SCRIPT;

    // прелоадер при получении времени
    const [isLoaded, setIsLoaded] = useState(false);

    // массив времени
    const [items, setItems] = useState([]);

    // показ модали
    const [isModal, setModal] = useState(false);

    // массив контента для модали
    const [isModalContent, setModalContent] = useState([]);

    // закрывашка и очистка контента модали
    const onClose = () => {
        setModal(false);
        setModalContent([]);
    }

    const config = {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded'
        }
    };

    // обновлялка времени
    useEffect(() => {
        document.title = 'История вождения | Права Мобил';

        setIsLoaded(true);
        setItems([]);

        const data = {
            path: 'client/practice/history',
            school_id: props.auth.school_id,
            client_id: props.auth.client_id
        };

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;
            setIsLoaded(false);
            if (response.status === 'done') {
                setItems(response.history);
            }
        })
    }, [setItems]);

    // модальное окно
    const Modal = ({visible = false, content}) => {
        if (!visible) return null;

        return (
            <div className="practice-history-modal position-fixed">
                <div className="practice-history-modal-dialog text-center">
                    <div className="practice-history-modal-header">
                        <div className="practice-history-modal-title">{content.title} {content.time}</div>
                    </div>
                    <div className="practice-history-modal-confirm">
                        <div className="practice-history-modal-confirm-title">Отменить занятие?</div>
                        <div className="practice-history-modal-confirm-text">Занятие, или стоимость занятия, будет возвращено на счет!</div>
                        <div className="practice-history-modal-confirm-btn-block d-flex">
                            <div className="practice-history-modal-confirm-btn">
                                <div className="confirm-btn-no" onClick={onClose}>Нет</div>
                            </div>
                            <div className="practice-history-modal-confirm-btn">
                                <div className="confirm-btn-yes" onClick={() => cancelPractice()}>Да</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    // сохранение выбранного контента и запуск модали
    const timeClick = (item, index) => {
        if (item.status === 'Отмена' || item.status === 'Неявка' || item.status === 'Пройдено') return null;

        item.item = index;

        // сохранение данных о дате и времени занятия
        setModalContent(item);

        // запуск модальки
        setModal(true);
    }

    // отмена занятия
    const cancelPractice = () => {
        const data = {
            path: 'client/practice/cancel',
            client_id: props.auth.client_id,
            school_id: props.auth.school_id,
            instructor_id: isModalContent.instructor_id,
            date: isModalContent.dateFull,
            time_id: isModalContent.time_id,
            time_title: isModalContent.time
        };

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;
            let timesItems = items;

            if (response.status === 'done') {
                timesItems[isModalContent.item].status = 'Отмена';
                toast.success('Успешно', {
                    duration: 5000,
                    position: 'bottom-center'
                });
                props.client.practice++;
            } else {
                toast.error(response.error, {
                    duration: 5000,
                    position: 'bottom-center'
                });
            }

            setItems(timesItems);
            setModal(false);
        })
    }

    // формирование объекта из элементов времени
    const itemsElements = items.map((item, index) => {
        let statusColor = '';

        switch (item.status) {
            case 'Назначено': statusColor = ' text-green'; break;
            case 'Отменено': statusColor = ' text-gray'; break;
            case 'Неявка': statusColor = ' text-red'; break;
            default: statusColor = ''; break;
        }

        return (
            <div className="practice-history-item d-flex justify-content-between" key={index} onClick={() => timeClick(item, index)}>
                <div style={{width:'102px'}}>{item.dateShort} {item.time}</div>
                <div>{item.secondName} {item.name[0]}.{item.patronymic[0]}.</div>
                <div className={'text-right' + statusColor} style={{width:'102px'}}>{item.status}</div>
            </div>
        )
    });

    return (
        <React.Fragment>
            <div className="content">
                <div className="practice-history-page">
                    <div className="practice-history-block">
                        <div className="practice-history-header">
                            <div className="practice-history-title">История занятий</div>
                        </div>
                        <div className="practice-history-body">
                            { isLoaded === false ? (
                                <div className="practice-history-times custom-scroll">
                                    {itemsElements.length !== 0 ? (
                                        <div className="practice-history-table">
                                            <div className="practice-history-table-title d-flex justify-content-between">
                                                <div style={{width:'102px'}}>Дата и время</div>
                                                <div>Инструктор</div>
                                                <div className="text-right" style={{width:'102px'}}>Статус</div>
                                            </div>
                                            {itemsElements}
                                        </div>
                                    ) : (
                                        <div className="text-center text-bold mt-3">Занятия отсутствуют!</div>
                                    )}
                                </div>
                            ) : (
                                <div className="loader">
                                    <div className="circle"></div>
                                    <div className="circle"></div>
                                    <div className="circle"></div>
                                    <div className="circle"></div>
                                    <div className="circle"></div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="practice-history-button">
                        <div className="practice-history-cancel-text">Для отмены нажмите на занятие.<br/>Отмена доступна за {props.client.timeCancel}</div>
                    </div>
                </div>
            </div>
            <Modal
                visible={isModal}
                content={isModalContent}
            />
            <Toaster />
        </React.Fragment>
    )
}

export default History;
