import React, {useEffect, useState} from "react";
import toast, {Toaster} from 'react-hot-toast';
import axios from "axios";
import CloseSidebar from "../../Main/CloseSidebar";
import {NavLink} from "react-router-dom";

const TheoryQuestions = (props) => {
    CloseSidebar();

    const API_SCRIPT = process.env.REACT_APP_API_SCRIPT;
    const [isLoaded, setIsLoaded] = useState(false);
    const [themes, setThemes] = useState([]);
    const [themesRightAnswerFinish, setThemeRightAnswerFinish] = useState(0);

    const [isThemeResetModal, setThemeResetModal] = useState(false);
    const [isThemeReset, setThemeReset] = useState(false);

    const [isQuestionModal, setQuestionModal] = useState(false);
    const [isQuestionModalContent, setQuestionModalContent] = useState([]);

    // для выбранных ответов
    const [selectAnswerId, setSelectAnswerId] = useState(false);
    const [selectAnswerResult, setSelectAnswerResult] = useState(false);

    const config = {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded'
        }
    };

    useEffect(() => {
        document.title = 'Билеты ПДД | Права Мобил';

        const data = {
            path: 'client/theory/pdd/questions',
            client_id: props.auth.client_id
        };

        setIsLoaded(true);
        setThemeRightAnswerFinish(0);

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;

            if (response.status === 'done') {
                setThemes(response.themes);

                response.themes.forEach(theme => {
                    let themeQuestionRightCount = 0,
                        themeQuestionCount = 0;

                    if (theme.questions) {
                        themeQuestionCount = theme.questions.length;

                        theme.questions.forEach(question => {
                            if (question.answer && question.answer === 'true') {
                                themeQuestionRightCount++;
                            }
                        });

                        if (themeQuestionRightCount > 0 && themeQuestionCount > 0 && themeQuestionRightCount === themeQuestionCount) {
                            setThemeRightAnswerFinish(themesRightAnswerFinish + 1);
                        }
                    }
                })

                setIsLoaded(false);
            }
        });
    }, []);

    const themesBlock = themes.map((theme, index) => {
        if (!themes) return null;
        if (theme.questions && theme.questions.length === 0) return null;

        let themeQuestionCount = theme.questions.length,
            attempt = false;

        return (
            <div className="mb-3" key={ index }>
                <div className="card card-primary collapsed-card mb-1" key={ index }>
                    <div className="card-header" data-card-widget="collapse">
                        <h3 className="card-title" style={{ fontSize: '14px', lineHeight: '1.5' }}>{ theme.title }</h3>
                    </div>
                    <div className="card-body">
                        { theme.questions.map((question, index) => {
                            question.themeQuestionCount = themeQuestionCount;
                            question.index = (index + 1);

                            attempt = question.answer ? true : false;

                            return (
                                <div className={ question.index !== theme.questions.length ? 'border border-primary mb-3' : 'border border-primary' } style={{ borderRadius: '10px' }} key={ index } onClick={ () => questionOpen(question) }>
                                    <div className="d-flex align-items-center m-2">
                                        <div className="question-title mr-1">{ 'Вопрос ' + question.index }</div>
                                        { question.answer ? (
                                            <div className={ question.answer === 'true' ? 'text-green' : 'text-red' } style={{ width: '16px' }}>
                                                { question.answer === 'true' ? (<i className="fas fa-check"></i>) : ('') }
                                                { question.answer === 'false' ? (<i className="fas fa-times"></i>) : ('') }
                                            </div>
                                        ) : ('') }
                                    </div>
                                </div>
                            )
                        }) }
                    </div>
                </div>
                { attempt ? (
                    <div className="d-flex justify-content-between align-items-center ml-2 mr-2">
                        <small className="text-bold" onClick={ () => ThemeResetModalOpen(theme) }><i className="fas fa-undo mr-2"></i>Решать заново</small>
                    </div>
                ) : ('') }
            </div>
        )
    });

    const questionOpen = (question) => {
        setQuestionModalContent(question);
        setQuestionModal(true);
    }

    const questionClose = () => {
        setQuestionModal(false);
        setQuestionModalContent([]);

        setSelectAnswerResult(false);
        setSelectAnswerId(false)
    }

    const selectAnswer = (question, answer) => {
        setSelectAnswerResult(answer.id);
        setSelectAnswerId(question.id);
    }

    const AnswerSend = () => {
        if (!selectAnswerId) {
            toast.error('Сначала выберите вариант ответа', {
                duration: 5000,
                position: 'bottom-center'
            });

            return null;
        }

        const data = {
            path: 'client/theory/pdd/questions/add',
            client_id: props.auth.client_id,
            question: selectAnswerId,
            result: selectAnswerResult
        };

        if (props.auth.school_id) {
            data.school_id = props.auth.school_id;
        }

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;

            if (response.status === 'done') {
                let themesTemp = themes;

                themesTemp.forEach((theme, thIndex) => {
                    theme.questions.forEach((question, qIndex) => {
                        if (question.id === selectAnswerId) {
                            question.answer = response.result;
                        }
                    })
                });

                setThemes(themesTemp);

                if (response.result === 'true') {
                    toast.success('Правильный ответ', {
                        duration: 5000,
                        position: 'bottom-center'
                    });
                } else {
                    toast.error('Неправильный ответ', {
                        duration: 5000,
                        position: 'bottom-center'
                    });
                }

                let currentQuestionId = isQuestionModalContent.id,
                    currentQuestionTheme = isQuestionModalContent.theme_id;

                themes.forEach((theme, themeIndex) => {
                    if (theme.id === currentQuestionTheme) {
                        if (theme.questions) {
                            theme.questions.forEach((question, questionIndex) => {
                                if (question.id === currentQuestionId) {
                                    if (theme.questions[questionIndex + 1]) {
                                        questionClose();
                                        questionOpen(theme.questions[questionIndex + 1]);
                                    } else {
                                        setQuestionModal(false);
                                        setQuestionModalContent([]);
                                    }
                                }
                            });
                        }
                    }
                });

                setSelectAnswerResult(false);
                setSelectAnswerId(false);
            } else {
                toast.error('Произошла ошибка. Попробуйте позже', {
                    duration: 5000,
                    position: 'bottom-center'
                });
            }
        });
    }

    const QuestionModal = ({visible = false, content}) => {
        if (!visible) return null;

        let attempt = false,
            right, select;

        themes.forEach((theme, index) => {
            if (theme.id === content.theme_id) {
                theme.questions.forEach((question, index) => {
                    attempt = question.answer ? true : false;
                });
            }
        });

        content.answers.map((answer, index) => {
            if (answer.id === content.right_answer) right = answer.answer;
            if (answer.id === content.select_answer_id) select = answer.answer;
        });

        return (
            <div className="theory-video-modal position-fixed">
                <div className="theory-video-modal-dialog">
                    <div className="theory-video-modal-header">
                        <div className="theory-video-modal-header-title text-center mb-2" style={{ fontSize: '18px', fontWeight: 700, color: '#000' }}>{ content.theme }</div>
                        <div className="theory-video-modal-header-title suptitle text-center mb-2">Вопрос { content.index } / { content.themeQuestionCount }</div>
                        <div className="theory-video-modal-header-title text-center">{ content.title }</div>
                    </div>
                    <div className="theory-video-modal-body">
                        <div className="mb-2">
                            <img src={ content.image } className="w-100" alt="" />
                        </div>
                        { attempt ? (
                            content.answer ? (
                                <React.Fragment>
                                    <div className={ content.answer === 'true' ? 'text-green' : 'text-red' }><span className="text-bold">Ваш ответ:</span> { select }</div>
                                    <div><span className="text-bold">Правильный ответ:</span> { right }</div>
                                    <div><span className="text-bold">Комментарий:</span> { content.comment }</div>
                                </React.Fragment>
                            ) : ('')
                        ) : (
                            <React.Fragment>
                                <div className="mb-2">
                                    { content.answers.map((answer, index) => {
                                        return <div className={ selectAnswerResult === answer.id ? 'btn btn-primary w-100 mt-1 mb-1' : 'btn btn-default w-100 mt-1 mb-1' } onClick={ () => selectAnswer(content, answer) } key={ index }>{ answer.answer }</div>
                                    }) }
                                </div>
                                { !content.answer ? (
                                    <div className="btn btn-success w-100" onClick={ () => AnswerSend() }>Подтвердить ответ</div>
                                ) : ('') }
                            </React.Fragment>
                        ) }
                        <div className="btn btn-primary w-100 mt-3" onClick={ () => questionClose() }>Закрыть</div>
                    </div>
                </div>
            </div>
        )
    }

    const ThemeResetModalOpen = (theme) => {
        setThemeReset(theme);
        setThemeResetModal(true);
    }

    const ThemeResetClose = () => {
        setThemeReset(false);
        setThemeResetModal(false);
    }

    const ThemeReset = () => {
        const data = {
            path: 'client/theory/pdd/questions/reset',
            client_id: props.auth.client_id,
            theme_id: isThemeReset.id
        };

        if (props.auth.school_id) {
            data.school_id = props.auth.school_id;
        }

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;

            if (response.status === 'done') {

                toast.success('Ответы успешно сброшены', {
                    duration: 5000,
                    position: 'bottom-center'
                });

                document.location.reload()

            } else {
                toast.error('Произошла ошибка. Попробуйте позже', {
                    duration: 5000,
                    position: 'bottom-center'
                });
            }
        });
    }

    const ThemeResetModal = ({visible = false, content}) => {
        if (!visible) return null;

        return (
            <div className="theory-video-modal position-fixed">
                <div className="theory-video-modal-dialog">
                    <div className="theory-video-modal-header">
                        <div className="theory-video-modal-header-title text-center mb-2" style={{ fontSize: '18px', fontWeight: 700, color: '#000' }}>Начать заного билет</div>
                    </div>
                    <div className="theory-video-modal-body">
                        <div className="mb-2">
                            <div className="text-center">Вы собираетесь начать заного билет: "{ content.title }" и сбросить все ответы. Начать заного?</div>
                        </div>
                        <div className="btn btn-success w-100" onClick={ () => ThemeReset() }>Сбросить и начать заного</div>
                        <div className="btn btn-primary w-100 mt-3" onClick={ () => ThemeResetClose() }>Закрыть</div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            <div className="card ml-3 mr-3 mt-3 mb-3">
                <div className="card-body">
                    <div className="text-center">
                        <div className="text-bold mb-4">Билеты ПДД</div>
                        <div className="text-gray text-bold mb-4">Правильных ответов { themesRightAnswerFinish } / { themes.length }</div>
                    </div>
                    <div>
                        { isLoaded === false ? (
                            <div className="questions mt-3">
                                <div className="themes mt-2">{ themesBlock }</div>
                            </div>
                        ) : (
                            <div className="loader">
                                <div className="circle"></div>
                                <div className="circle"></div>
                                <div className="circle"></div>
                                <div className="circle"></div>
                                <div className="circle"></div>
                            </div>
                        ) }
                    </div>
                </div>
            </div>
            <div className="text-center ml-3 mr-3">
                { (props.auth.school_id && props.auth.type !== 'pdd') ? '' : (<NavLink to="/" className="btn btn-primary text-bold w-100">Назад</NavLink>) }
            </div>
            <QuestionModal visible={ isQuestionModal } content={ isQuestionModalContent }/>
            <ThemeResetModal visible={ isThemeResetModal } content={ isThemeReset }/>
            <Toaster/>
        </React.Fragment>
    )
}

export default TheoryQuestions;
