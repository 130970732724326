import React, {useEffect, useRef, useState} from 'react'
import axios from "axios";
import Countdown from "react-countdown";

const InternalModal = (pusherResponse = false) => {
    const API_SCRIPT = process.env.REACT_APP_API_SCRIPT;
    const auth = JSON.parse(localStorage.getItem('auth'));

    const [isLoader, setLoader] = useState(false);

    const [isModal, setModal] = useState(false);
    const [isMessage, setMessage] = useState([]);
    const [isSelect, setSelect] = useState(false);
    const [isSelectValue, setSelectValue] = useState(false);

    const [isDuration, setDuration] = useState(0);
    const [isCategory, setCategory] = useState([]);
    const [isQuestions, setQuestions] = useState([]);
    const [isQuestionsType, setQuestionsType] = useState('general');
    const [isQuestion, setQuestion] = useState([]);
    const [isQuestionId, setQuestionId] = useState(0);

    const [isResult, setResult] = useState([]);

    let questions = JSON.parse(localStorage.getItem('questions'));
    const [answers, setAnswers] = useState([]);

    const test = useRef(0);

    const config = {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded'
        }
    };

    useEffect(() => {
        const data = {
            path: 'client/examens/internal/theory',
            client_id: auth.client_id
        };

        if (auth.school_id) {
            data.school_id = auth.school_id;
        }

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;

            if (response.status === 'done') {
                setMessage(response.data);
                setModal(true);
            }
        });
    }, []);

    if (pusherResponse && pusherResponse.pusherResponse.response) {
        if (pusherResponse.pusherResponse.response.type === 'examen-internal-write' ) {
            if (test.current === 0) {
                setMessage({
                    id: pusherResponse.pusherResponse.response.id,
                    exam_type: pusherResponse.pusherResponse.response.exam_type,
                    type: pusherResponse.pusherResponse.response.type
                });

                setModal(true);
                test.current++;
            }
        }
    }

    const modalClose = type => {
        localStorage.removeItem('answers');
        localStorage.removeItem('questions');
        setModal(false);
        setMessage([]);

        if (type === 'reload') document.location.reload();
    }

    const modalExamenStart = (type, examen) => {
        setLoader(true);
        let questions = JSON.parse(localStorage.getItem('questions')),
            answersStorage = JSON.parse(localStorage.getItem('answers'));

        if (!answersStorage || answersStorage === 0) {
            localStorage.removeItem('questions');
        }

        const data = {
            path: 'client/examens/internal/theory/start',
            client_id: auth.client_id,
            school_id: auth.school_id,
            examen_id: isMessage.id,
            exam_type: type,
            type: isQuestionsType,
            answers: answersStorage
        }

        if (isResult && isResult.questions) data.questions = isResult.questions;

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;

            if (response.status === 'done') {
                setDuration(Date.now() + (response.duration * 1000));
                setCategory(response.category);

                if (!questions || questions.length === 0) {
                    localStorage.setItem('questions', JSON.stringify(response.questions));
                    questions = response.questions;
                    setQuestions(response.questions);
                }

                if (!answersStorage || answersStorage === 0) {
                    setQuestion(response.questions[0]);
                    setQuestionId(0);
                } else {
                    if (isQuestionsType === 'general') {
                        setQuestion(questions[answersStorage.length]);
                        setQuestionId(answersStorage.length);
                        setAnswers(answersStorage);
                        setQuestions(questions);
                    } else {
                        setQuestion(response.questions[0]);
                        setQuestionId(0);
                    }
                }

                setMessage({
                    id: isMessage.id,
                    exam_type: type,
                    type: 'examen-internal-start'
                });
                setLoader(false);
            }
        });
    }

    const answerSelect = (question, answer) => {
        setSelectValue(answer)

        let index = false,
            answersData = answers;

        answersData.map((element, key) => {
            if (element.question === question) index = key;
        });

        if (index) {
            answersData[index]['answer'] = answer;
        } else {
            answersData = answers.concat({
                question: question,
                answer: answer
            });
        }

        setAnswers(answersData);
        setSelect(true);
    }

    const nextQuestion = () => {

        //if ((isQuestionId + 1) > answers.length) return false;

        setSelect(false);
        setSelectValue(false);

        localStorage.setItem('answers', JSON.stringify(answers));

        if ((isQuestionId + 1) < questions.length) {
            setQuestion(questions[isQuestionId + 1]);
            setQuestionId(isQuestionId + 1);
        } else {
            setLoader(true);

            const data = {
                path: 'client/examens/internal/theory/result',
                client_id: auth.client_id,
                school_id: auth.school_id,
                examen_id: isMessage.id,
                exam_type: isMessage.exam_type,
                answers: answers,
                type: isQuestionsType
            }

            axios.post(API_SCRIPT, data, config).then(response => {
                response = response.data;

                if (response.status === 'done') {
                    setResult(response);
                    setQuestionsType('additional');

                    localStorage.removeItem('questions');

                    setMessage({
                        id: isMessage.id,
                        exam_type: isMessage.exam_type,
                        type: 'examen-internal-result'
                    });
                    setLoader(false);
                }
            });
        }
    }

    const SendTheoryAnswers = () => {
        setLoader(true);

        const data = {
            path: 'client/examens/internal/theory/result',
            client_id: auth.client_id,
            school_id: auth.school_id,
            examen_id: isMessage.id,
            exam_type: isMessage.exam_type,
            answers: answers,
            type: isQuestionsType
        }

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;

            if (response.status === 'done') {
                setResult(response);
                setQuestionsType('additional');

                localStorage.removeItem('questions');

                setMessage({
                    id: isMessage.id,
                    exam_type: isMessage.exam_type,
                    type: 'examen-internal-result'
                });
                setLoader(false);
            }
        });
    }

    const QuestionList = () => {
        setLoader(true);

        const data = {
            path: 'client/examens/internal/theory/answers',
            answers: answers
        }

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;

            if (response.status === 'done') {
                setQuestions(response.questions);
                setQuestion(response.questions[0]);
                setQuestionId(0);

                localStorage.removeItem('answers');

                setMessage({
                    id: isMessage.id,
                    exam_type: isMessage.exam_type,
                    type: 'examen-internal-answers'
                });
                setLoader(false);
            }
        });
    }

    const QuestionListAnswerClick = key => {
        setLoader(true);
        setQuestion(isQuestions[key]);
        setQuestionId(key);
        setLoader(false);
    }

    const TimerRenderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            SendTheoryAnswers();
            return <div className="text-red text-bold">Время вышло</div>
        } else {
            hours = hours < 10 ? '0' + hours : hours;
            minutes = minutes < 10 ? '0' + minutes : minutes;
            seconds = seconds < 10 ? '0' + seconds : seconds;

            return <div className="text-red text-bold">{ minutes + ':' + seconds }</div>
        }
    }

    const Modal = ({visible = false, content}) => {
        if (!visible || visible === false) return null;

        if (isLoader) {
            return (
                <div className="modal position-fixed">
                    <div className="modal-dialog text-center">
                        <div className="modal-header d-block">
                            <div className="modal-header-title">Загрузка экзамена</div>
                        </div>
                        <div className="modal-confirm pt-4 pb-4">
                            <div className="modal-confirm-title pb-4">Ожидайте</div>
                            <div className="loader">
                                <div className="circle"></div>
                                <div className="circle"></div>
                                <div className="circle"></div>
                                <div className="circle"></div>
                                <div className="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if (content.type === 'examen-internal-write') {
            let answersStorage = JSON.parse(localStorage.getItem('answers'));

            return (
                <div className="modal position-fixed">
                    <div className="modal-dialog text-center">
                        <div className="modal-header d-block">
                            <div className="text-center">
                                <svg width="110" height="110" viewBox="0 0 1014 894" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M994.243 238.987C1006.1 234.532 1014.01 222.665 1014 209.364C1013.98 196.062 1006.04 184.216 994.177 179.791L516.885 1.78983C510.49 -0.596608 503.512 -0.596608 497.117 1.78983L19.8245 179.791C7.95755 184.214 0.0158675 196.062 2.37399e-05 209.364C-0.01582 222.665 7.90012 234.532 19.7572 238.987L208.831 310.035V514.865C208.831 548.67 242.196 577.26 307.999 599.834C365.355 619.512 436.03 630.349 507.002 630.349C577.974 630.349 648.647 619.512 706.005 599.834C771.808 577.26 805.173 548.673 805.173 514.865V310.035L883.238 280.701V564.528C848.522 577.502 823.576 612.522 823.576 653.604C823.576 694.685 848.522 729.707 883.238 742.681V862.6C883.238 879.941 896.538 894 912.945 894C929.351 894 942.652 879.941 942.652 862.6V742.681C977.367 729.707 1002.31 694.687 1002.31 653.604C1002.31 612.524 977.367 577.502 942.652 564.528V258.373L994.243 238.987ZM912.943 685.265C896.426 685.265 882.988 671.061 882.988 653.602C882.988 636.144 896.426 621.94 912.943 621.94C929.46 621.94 942.897 636.144 942.897 653.602C942.897 671.061 929.46 685.265 912.943 685.265ZM507 64.6975L895.309 209.514L507 355.428L118.691 209.512L507 64.6975ZM745.757 510.132C740.064 516.198 719.681 530.728 673.676 544.601C624.598 559.399 565.404 567.548 507 567.548C448.596 567.548 389.404 559.399 340.324 544.601C294.319 530.728 273.936 516.2 268.243 510.132V332.361L497.048 418.34C500.266 419.55 503.633 420.155 506.998 420.155C510.365 420.155 513.73 419.55 516.948 418.34L745.753 332.361V510.132H745.757Z" fill="#899CB1"/>
                                </svg>
                            </div>
                            <div className="modal-header-title">Вам назначен экзамен</div>
                        </div>
                        <div className="modal-confirm">
                            <div className="modal-confirm-title">{ answersStorage !== null ? ('Продолжить?') : ('Начать?') }</div>
                            <div className="modal-confirm-btn-block d-flex">
                                <div className="modal-confirm-btn">
                                    <div className="confirm-btn-no" onClick={ () => modalClose('close') }>Нет</div>
                                </div>
                                <div className="modal-confirm-btn">
                                    <div className="confirm-btn-yes" onClick={ () => modalExamenStart(content.exam_type, content.id) }>Да</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if (content.type === 'examen-internal-start') {
            let answersStorage = JSON.parse(localStorage.getItem('answers'));

            return (
                <div className="modal position-fixed">
                    <div className="modal-dialog modal-lg text-center">
                        <div className="modal-header">
                            <div className="modal-header-title w-100 d-flex justify-content-between">
                                <div className="">{ isCategory }</div>
                                <Countdown date={isDuration} renderer={TimerRenderer} />
                                { (isQuestionId) < questions.length ? (
                                    <div className="">Вопрос { isQuestionId + 1 } из { isQuestions.length }</div>
                                ) : ('') }
                            </div>
                        </div>
                        { isQuestion ? (
                            <div className="modal-confirm overflow-auto" style={{ maxHeight: 'calc(100vh - 120px)' }}>
                                <img src={ isQuestion.image } style={{ display: 'block', maxWidth: '800px', maxHeight: '800px', width: '100%', height: '100%', margin: '0 auto' }} alt="img" />
                                <div className="text-left mt-4 mr-4 ml-4">
                                    <div className="text-bold pb-2">{ isQuestion.title }</div>
                                    <div className="answers pb-2">
                                        { isQuestion.answers.map((element, key) => {
                                            return (
                                                <div className="custom-control custom-radio pb-2" key={ key }>
                                                    <input className="custom-control-input" type="radio" id={ 'answer-' + element.id } name={ 'answer[' + isQuestion.id + ']' } onChange={ () => answerSelect(isQuestion.id, element.id) } checked={ isSelectValue === element.id } />
                                                    <label htmlFor={ 'answer-' + element.id } className="custom-control-label font-weight-normal">{ element.answer }</label>
                                                </div>
                                            )
                                        }) }
                                    </div>
                                    <div className="alert alert-secondary text-center">Выберите один ответ кликнув по нему. Если вы уверены в ответе нажмите "Принять ответ"</div>
                                </div>
                                <div className="pagination-block d-flex justify-content-between align-items-baseline mb-4 mr-4 ml-4">
                                    <button type="button" disabled={ !isSelect ? true : false } className={ 'btn btn-xs btn-primary' + (!isSelect ? ' disabled' : '') } onClick={ nextQuestion }>Принять ответ</button>
                                    <ul className="pagination justify-content-center flex-wrap mb-0">
                                        { questions.map((element, key) => {
                                            return <li className="page-item" key={ key }><div className={ 'page-link' + (((isQuestionId >= key) || (isQuestionsType === 'general' && answersStorage && answersStorage[key])) ? ' bg-primary' : '') }>{ key + 1 }</div></li>
                                        }) }
                                    </ul>
                                    <div className="btn btn-xs btn-success d-none">Список вопросов</div>
                                </div>
                            </div>
                        ) : ('') }
                    </div>
                </div>
            )
        }

        if (content.type === 'examen-internal-result') {
            return (
                <div className="modal position-fixed">
                    <div className="modal-dialog text-center">
                        <div className="modal-header d-block">
                            { (isResult && isResult.type === 'errors' && isResult.errors !== 0 && isResult.qtype === 'general' && isResult.pdd > 0 && isResult.questions) ? (
                                <React.Fragment>
                                    <div className="text-center">
                                        <svg width="110" height="110" viewBox="0 0 358 299" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M223.343 23.9547L353.454 233.506C356.317 239.761 357.57 244.847 357.928 250.13C358.644 262.475 353.991 274.475 344.863 283.709C335.736 292.91 323.387 298.342 309.964 299H47.9524C42.4043 298.687 36.8562 297.519 31.6661 295.708C5.7155 285.997 -6.81239 258.673 3.74683 234.987L134.753 23.8066C139.227 16.3832 146.028 9.98026 154.439 5.86528C178.779 -6.66072 209.741 1.56924 223.343 23.9547ZM194.519 162.058C194.519 169.959 187.539 176.559 178.949 176.559C170.358 176.559 163.199 169.959 163.199 162.058V115.493C163.199 107.576 170.358 101.173 178.949 101.173C187.539 101.173 194.519 107.576 194.519 115.493V162.058ZM178.949 232.845C170.358 232.845 163.199 226.245 163.199 218.361C163.199 210.443 170.358 203.859 178.949 203.859C187.539 203.859 194.519 210.295 194.519 218.18C194.519 226.245 187.539 232.845 178.949 232.845Z" fill="#FFA723"/>
                                        </svg>
                                    </div>
                                    <div className="modal-header-title text-uppercase text-orange">Допущены ошибки</div>
                                </React.Fragment>
                            ) : (isResult && (isResult.type === 'time' || (isResult.type === 'errors' && isResult.errors !== 0))) ? (
                                <React.Fragment>
                                    <div className="text-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="110" height="110" viewBox="0 0 400 428" fill="none">
                                            <path d="M383.635 187.682C388.055 186.048 391.005 181.694 391 176.813C390.994 171.933 388.034 167.587 383.611 165.963L205.685 100.657C203.301 99.7811 200.7 99.7811 198.316 100.657L20.3902 165.963C15.9664 167.586 13.0059 171.933 13 176.813C12.9941 181.694 15.945 186.048 20.3651 187.682L90.8481 213.749V288.899C90.8481 301.302 103.286 311.791 127.816 320.073C149.197 327.293 175.544 331.269 202.001 331.269C228.458 331.269 254.803 327.293 276.185 320.073C300.716 311.791 313.153 301.303 313.153 288.899V213.749L342.254 202.987V307.12C329.313 311.88 320.014 324.729 320.014 339.801C320.014 354.873 329.313 367.722 342.254 372.483V416.479C342.254 422.842 347.213 428 353.328 428C359.444 428 364.403 422.842 364.403 416.479V372.483C377.344 367.722 386.643 354.874 386.643 339.801C386.643 324.729 377.344 311.88 364.403 307.12V194.795L383.635 187.682ZM353.328 351.417C347.17 351.417 342.161 346.206 342.161 339.8C342.161 333.395 347.17 328.184 353.328 328.184C359.485 328.184 364.494 333.395 364.494 339.8C364.494 346.206 359.485 351.417 353.328 351.417ZM202 123.737L346.754 176.869L202 230.403L57.2459 176.868L202 123.737ZM291.004 287.162C288.882 289.388 281.283 294.719 264.134 299.809C245.838 305.238 223.772 308.228 202 308.228C180.228 308.228 158.162 305.238 139.866 299.809C122.717 294.719 115.118 289.389 112.996 287.162V221.94L198.29 253.485C199.49 253.929 200.745 254.151 201.999 254.151C203.254 254.151 204.509 253.929 205.708 253.485L291.003 221.94V287.162H291.004Z" fill="#DADADA"/>
                                            <line x1="7.92893" y1="374.978" x2="374.978" y2="7.92871" stroke="#EF2323" strokeWidth="20"/>
                                            <line x1="25.0711" y1="7.92893" x2="392.12" y2="374.978" stroke="#EF2323" strokeWidth="20"/>
                                        </svg>
                                    </div>
                                    <div className="modal-header-title text-uppercase text-red">Экзамен не сдан</div>
                                </React.Fragment>
                            ) : (isResult && isResult.type === 'success') ? (
                                <React.Fragment>
                                    <div className="text-center">
                                        <svg width="110" height="110" viewBox="0 0 378 328" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M370.635 87.6819C375.055 86.0475 378.005 81.6936 378 76.8135C377.994 71.9334 375.034 67.5871 370.611 65.9634L192.685 0.65667C190.301 -0.21889 187.7 -0.21889 185.316 0.65667L7.3902 65.9634C2.96642 67.5863 0.0059151 71.9334 8.8498e-06 76.8135C-0.0058974 81.6936 2.94501 86.0475 7.3651 87.6819L77.8481 113.749V188.899C77.8481 201.302 90.2859 211.791 114.816 220.073C136.197 227.293 162.544 231.269 189.001 231.269C215.458 231.269 241.803 227.293 263.185 220.073C287.716 211.791 300.153 201.303 300.153 188.899V113.749L329.254 102.987V207.12C316.313 211.88 307.014 224.729 307.014 239.801C307.014 254.873 316.313 267.722 329.254 272.483V316.479C329.254 322.842 334.213 328 340.328 328C346.444 328 351.403 322.842 351.403 316.479V272.483C364.344 267.722 373.643 254.874 373.643 239.801C373.643 224.729 364.344 211.88 351.403 207.12V94.7947L370.635 87.6819ZM340.328 251.417C334.17 251.417 329.161 246.206 329.161 239.8C329.161 233.395 334.17 228.184 340.328 228.184C346.485 228.184 351.494 233.395 351.494 239.8C351.494 246.206 346.485 251.417 340.328 251.417ZM189 23.7369L333.754 76.8688L189 130.403L44.2459 76.868L189 23.7369ZM278.004 187.162C275.882 189.388 268.283 194.719 251.134 199.809C232.838 205.238 210.772 208.228 189 208.228C167.228 208.228 145.162 205.238 126.866 199.809C109.717 194.719 102.118 189.389 99.9958 187.162V121.94L185.29 153.485C186.49 153.929 187.745 154.151 188.999 154.151C190.254 154.151 191.509 153.929 192.708 153.485L278.003 121.94V187.162H278.004Z" fill="#23AF85"/>
                                        </svg>
                                    </div>
                                    <div className="modal-header-title text-uppercase text-green">Экзамен сдан</div>
                                </React.Fragment>
                            ) : ('') }
                        </div>
                        <div className="modal-confirm">
                            <div className="modal-confirm-title">
                                { (isResult && isResult.type === 'errors' && isResult.questions && isResult.qtype === 'general' && isResult.pdd > 0) ? (
                                    <React.Fragment>
                                        <div className="text-black-50 text-md">Необходимо ответить на дополнительные вопросы</div>
                                        <div className="btn btn-warning btn-sm" onClick={ () => modalExamenStart(isMessage.exam_type, isMessage.id) }>Продолжить</div>
                                    </React.Fragment>
                                ) : ('') }
                                { (isResult && (isResult.type === 'time' || isResult.type === 'errors')) ? (
                                    <React.Fragment>
                                        { isResult.type === 'time' ? (<div className="text-black-50 text-md">Закончилось время</div>) : ('') }
                                        { (isResult.type === 'errors' && isResult.qtype === 'general') ? (<div className="text-black-50 text-md">Допущено { isResult.errors } ошибок</div>) : ('') }
                                        { (isResult.type === 'errors' && !isResult.questions && isResult.qtype === 'additional') ? (<div className="text-black-50 text-md">Ошибки в дополнительных вопросах</div>) : ('') }
                                    </React.Fragment>
                                ) : ('') }
                                { (isResult && (isResult.type === 'time' || isResult.type === 'errors' || isResult.type === 'success') && !isResult.questions) ? (
                                    <div className={ 'btn' + (isResult.type === 'success' ? '  btn-outline-success' : ' btn-outline-danger') } onClick={ () => QuestionList() }>Посмотреть комментарии к ответам</div>
                                ) : ('') }
                            </div>
                            { (isResult && (isResult.type === 'success' || (isResult.type === 'errors' && !isResult.questions))) ? (
                                <div className="modal-confirm-btn-block">
                                    <div className="modal-confirm-btn m-auto">
                                        <div className="confirm-btn-no" onClick={ () => modalClose('reload') }>Закрыть</div>
                                    </div>
                                </div>
                            ) : ('') }
                        </div>
                    </div>
                </div>
            )
        }

        if (content.type === 'examen-internal-answers') {
            return (
                <div className="modal position-fixed">
                    <div className="modal-dialog modal-lg text-center">
                        <div className="modal-header">
                            <div className="modal-header-title">Просмотр комментария к ответам</div>
                        </div>
                        { isQuestion ? (
                            <div className="modal-confirm overflow-auto" style={{ maxHeight: 'calc(100vh - 120px)' }}>
                                <img src={ isQuestion.image } style={{ display: 'block', maxWidth: '800px', maxHeight: '800px', width: '100%', height: '100%', margin: '0 auto' }} alt="img" />
                                <div className="d-flex text-left mt-4 mr-4 ml-4">
                                    <div className="w-50">
                                        <div className="text-bold pb-2">{ isQuestion.title }</div>
                                        <div className="answers pb-2">
                                            { isQuestion.answers.map((element, key) => {
                                                return (
                                                    <div className="d-flex" key={ key }>
                                                        <span className={ element.class + ' rounded-circle border' } style={{ minWidth: '20px', height: '20px' }}></span>
                                                        <div className="ml-1">{ element.id + '. ' + element.answer }</div>
                                                    </div>
                                                )
                                            }) }
                                        </div>
                                    </div>
                                    <div className="w-50">
                                        { isQuestion.select_answer !== isQuestion.right_answer ? (
                                            <div className="d-flex">
                                                <div className="w-50">
                                                    <div className="d-flex">
                                                        <span className="bg-primary rounded-circle border" style={{ minWidth: '20px', height: '20px' }}></span>
                                                        <div className="ml-1">Ваш ответ: { isQuestion.select_answer }</div>
                                                    </div>
                                                </div>
                                                <div className="w-50">
                                                    <div className="d-flex">
                                                        <span className="bg-green rounded-circle border" style={{ minWidth: '20px', height: '20px' }}></span>
                                                        <div className="ml-1">Правильный ответ: { isQuestion.right_answer }</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="d-flex">
                                                <div className="w-100">
                                                    <div className="d-flex">
                                                        <span className="bg-green rounded-circle border" style={{ minWidth: '20px', height: '20px' }}></span>
                                                        <div className="ml-1">Ваш ответ правильный: { isQuestion.select_answer }</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) }
                                        { isQuestion.comment ? (
                                            <div className="mt-2">
                                                <div className="text-bold text-primary">Комментарий:</div>
                                                <div>{ isQuestion.comment }</div>
                                            </div>
                                        ) : ('') }
                                    </div>
                                </div>
                                <div className="pagination-block d-flex justify-content-between align-items-baseline mb-4 mr-4 ml-4">
                                    <ul className="pagination justify-content-center mb-0">
                                        { isQuestions.map((element, key) => {
                                            return <li className="page-item" key={ key }><div className={ 'page-link' + (isQuestionId === key ? ' bg-primary' : '') } onClick={ () => QuestionListAnswerClick(key) } style={{ cursor: 'pointer' }}>{ key + 1 }</div></li>
                                        }) }
                                    </ul>
                                    <div className="btn btn-xs btn-success" onClick={ () => modalClose('close') }>Выход</div>
                                </div>
                            </div>
                        ) : ('') }
                    </div>
                </div>
            )
        }
    }

    return <Modal visible={ isModal } content={ isMessage } />
}

export default InternalModal
