import React, { useEffect, useState } from 'react';
import axios from "axios";
import Header from './components/Main/Header';
import Sidebar from './components/Main/Sidebar';
import Content from './components/Main/Content';
import Auth from "./components/Pages/Auth";
import Welcome from "./components/Pages/Welcome";
import { Route, Routes } from "react-router-dom";
import Select from "./components/Pages/Select";
import Getapp from "./components/Pages/Getapp";
import TheoryRegister from "./components/Pages/Theory/TheoryRegister";
import TheoryLogin from "./components/Pages/Theory/TheoryLogin";
import TheoryAuth from "./components/Pages/Theory/TheoryAuth";
import InternalModal from "./components/Content/Examens/InternalModal";

import Pusher from 'pusher-js';

function App() {
    const API_SCRIPT = process.env.REACT_APP_API_SCRIPT;
    const auth = JSON.parse(localStorage.getItem('auth'));
    const [client, setClient] = useState([])
    const [school, setSchool] = useState([]);
    const [instructor, setInstructor] = useState([]);

    const [pusherResponse, setPusherResponse] = useState(false);

    if ((auth && (!auth.school_id || (auth.school_id && auth.school_id === 0))) || (auth && auth.type === 'pdd')) {
        document.body.classList.add('sidebar-hidden');
    }

    const config = {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded'
        }
    };

    useEffect(() => {
        //Pusher.logToConsole = true;
        var pusher = new Pusher('70e5d6975918cea1e498', {
            cluster: 'eu',
            forceTLS: true
        })

        if (!auth) return null;

        const channel = pusher.subscribe('web-app-client-' + auth.client_id);
        channel.bind('ExamenInternal', (response) => setPusherResponse(response))

        const data = {
            path: 'client/info',
            client_id: auth.client_id
        };

        if (auth.school_id) {
            data.school_id = auth.school_id;
        }

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;

            if (response.status === 'done') {
                setClient(response.client);

                if (auth.school_id) {
                    setInstructor(response.instructor ?? false);
                    setSchool(response.school);

                    auth.type = response.school.tariffType;
                    localStorage.setItem('auth', JSON.stringify(auth));
                }
            }
        });

        const rememberTime = () => {
            const date = Math.round(Date.now() / 1000);
            const login = auth.login;
            const remember = auth.remember === 1 ? 604800 : 3800;

            if (date > (+login + +remember)) {
                localStorage.clear();
                window.location.reload();
            }
        };

        rememberTime();
        setInterval(rememberTime, 10000);
    }, []);

    if (!auth || auth === null) {
        if (window.location.pathname !== '/') window.location.href = '/';

        return (
            <Routes>
                <Route path="/auth" exact element={<Auth />} />
                <Route path="/select" exact element={<Select />} />
                <Route path="/getapp" exact element={<Getapp />} />
                <Route path="/theory/auth" exact element={<TheoryAuth />} />
                <Route path="/theory/login" exact element={<TheoryLogin />} />
                <Route path="/theory/register" exact element={<TheoryRegister />} />
                <Route path="/" exact element={<Welcome />} />
            </Routes>
        )
    }

    return (
        <div className="wrapper">
            <Header client={client} school={school} instructor={instructor} auth={auth} />
            <Sidebar school={school} auth={auth} />
            <Content client={client} school={school} instructor={instructor} auth={auth} />
            <InternalModal pusherResponse={pusherResponse} />
        </div>
    );
}

export default App;
