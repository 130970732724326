import CloseSidebar from "../Main/CloseSidebar";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Balance from "../Main/Components/Balance";

function Home(props) {
    CloseSidebar();

    const API_SCRIPT = process.env.REACT_APP_API_SCRIPT;
    const [items, setItems] = useState([]);

    const config = {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded'
        }
    };

    // обновлялка времени
    useEffect(() => {
        setItems([]);

        const data = {
            path: 'pushs',
            school_id: props.auth.school_id,
            id: props.auth.client_id,
            type: 'clients'
        };

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;

            if (response.status === 'done') {
                setItems(response.pushs);
            }
        })
    }, [setItems]);

    const itemsElements = items.map((item, index) => {
        return (
            <div className="card card-primary card-outline" key={ index }>
                <div className="card-body p-1 pl-2 pr-2">
                    <small className="badge badge-primary">{ item.date }</small>
                    <div>{ item.text }</div>
                </div>
            </div>
        )
    });

    return (
        <div className="content p-0" style={{height:'inherit'}}>
            <div className="home-wrapper" style={{height:'inherit'}}>
                <Balance client={props.client} school={props.school} auth={props.auth} />
                <h4 className="p-2 d-none">Уведомления</h4>
                <div className="p-2 d-none" style={{maxHeight:'calc(100% - 60px)',height:'auto',overflowY:'auto'}}>{ itemsElements}</div>
            </div>
        </div>
    )
}

export default Home;
