import {Route, Routes} from "react-router-dom";
import Home from "../Pages/Home";
import Contact from "../Pages/Contact";
import Write from "../Content/Practice/Write";
import History from "../Content/Practice/History";
import Info from "../Content/Pay/Info";
import Next from "../Content/Pay/Next";
import Early from "../Content/Pay/Early";
import Services from "../Content/Pay/Services";
import TheoryInfo from "../Content/Theory/TheoryInfo";
import TheoryVideos from "../Content/Theory/TheoryVideos";
import TheoryQuestions from "../Content/Theory/TheoryQuestions";
import Getapp from "../Pages/Getapp";
import React from "react";
import Internal from "../Content/Examens/Internal";

const Content = (props) => {
    const auth = JSON.parse(localStorage.getItem('auth'));

    if (auth.school_id && auth.type !== 'pdd') {
        return (
            <div className="content-wrapper position-relative">
                <Routes>
                    <Route path="/" exact element={<Home client={props.client} school={props.school} instructor={props.instructor} auth={props.auth} />}/>
                    <Route path="/contact" element={<Contact client={props.client} school={props.school} instructor={props.instructor} auth={props.auth} />}/>
                    <Route path="/practice/write" element={<Write client={props.client} school={props.school} instructor={props.instructor} auth={props.auth} />}/>
                    <Route path="/practice/history" element={<History client={props.client} school={props.school} instructor={props.instructor} auth={props.auth} />}/>
                    <Route path="/pay/info" element={<Info client={props.client} school={props.school} auth={props.auth} />}/>
                    <Route path="/pay/next" element={<Next client={props.client} school={props.school} auth={props.auth} />}/>
                    <Route path="/pay/early" element={<Early client={props.client} school={props.school} auth={props.auth} />}/>
                    <Route path="/pay/services" element={<Services client={props.client} school={props.school} auth={props.auth} />}/>
                    <Route path="/theory/questions" element={<TheoryQuestions client={props.client} school={props.school} auth={props.auth} />}/>
                    <Route path="/theory/videos" element={<TheoryVideos client={props.client} school={props.school} auth={props.auth} />}/>
                    <Route path="/theory/online" element={<TheoryInfo client={props.client} school={props.school} auth={props.auth} />}/>
                    <Route path="/examens/internal" element={<Internal client={props.client} school={props.school} auth={props.auth} />}/>
                </Routes>
            </div>
        )
    }

    return (
        <div className="content-wrapper position-relative">
            <Routes>
                <Route path="/contact" element={<Contact client={props.client} school={props.school} instructor={props.instructor} auth={props.auth} />}/>
                <Route path="/questions" exact element={<TheoryQuestions client={props.client} auth={props.auth} />}/>
                <Route path="/videos" exact element={<TheoryVideos client={props.client} auth={props.auth} />}/>
                <Route path="/getapp" exact element={<Getapp />} />
                <Route path="/" exact element={<TheoryInfo client={props.client} auth={props.auth} />}/>
            </Routes>
        </div>
    )
}

export default Content;
