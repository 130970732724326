import { Link } from "react-router-dom";

function Header(props) {
    const auth = JSON.parse(localStorage.getItem('auth'));

    const clientLogout = () => {
        localStorage.clear();
        window.location.reload();
    }

    return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            <ul className="navbar-nav">
                { (auth.school_id && auth.school_id !== 0 && auth.type !== 'pdd') ? (
                    <li className="nav-item">
                        <a className="nav-link toggle-sidebar" data-widget="pushmenu" href="/" role="button">
                            <i className="fas fa-bars"></i>
                        </a>
                    </li>
                ) : ('') }
                <li className="nav-item d-sm-inline-block">
                    <Link to="/" className="nav-link link d-none">Главная</Link>
                </li>
                <li className="nav-item d-sm-inline-block">
                    <Link to="/contact" className="nav-link link d-none">Связь</Link>
                </li>
            </ul>
            <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown">
                    <a className="d-flex" data-toggle="dropdown" href="/" aria-expanded="false">
                        <div className="custom-nav-avatar">
                            <img src={props.client.photo} alt=""/>
                        </div>
                        <div className="user-info ml-2">
                            <div className="user-info-names">{props.client.lastname} {props.client.name} {props.client.patronymic}</div>
                            <div className="user-info-phone">{props.client.phoneFormat}</div>
                        </div>
                    </a>
                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                        <div className="avatar text-center mt-2 position-relative">
                            <img src={props.client.photo} alt=""/>
                        </div>
                        <span className="d-block text-bold text-center mt-2 mb-2">{props.client.lastname} {props.client.name} </span>
                        <span className="d-block text-center mt-2 mb-2">{props.client.phoneFormat}</span>
                        <a href="/" className="dropdown-item position-relative" onClick={clientLogout}>
                            <i className="far fa-times-circle mr-2"></i> Выйти из системы
                        </a>
                    </div>
                </li>
            </ul>
        </nav>
    )
}

export default Header;
