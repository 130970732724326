import React, {useEffect, useState} from "react";
import axios from "axios";

const Balance = (props) => {
    const API_SCRIPT = process.env.REACT_APP_API_SCRIPT;

    const [isLoaded, setIsLoaded] = useState(false);
    const [isPayInfo, setPayInfo] = useState([]);
    const [isPayModal, setPayModal] = useState(false);
    const [isBalanceMoney, setBalanceMoney] = useState(0);
    const [isBalanceBonus, setBalanceBonus] = useState(0);

    const config = {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded'
        }
    };

    useEffect(() => {
        setIsLoaded(true);

        const data = {
            path: 'client/pay/info',
            school_id: props.auth.school_id,
            client_id: props.auth.client_id
        };

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;

            if (response.status === 'done') {
                setPayInfo(response);

                if (response.balance && response.balance.money) setBalanceMoney(response.balance.money);
                if (response.balance && response.balance.bonus) setBalanceBonus(response.balance.bonus);

                setIsLoaded(false)
            }
        })
    }, [])

    const moneyFormat = amount => {
        let format = parseInt(amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1 ");
        format = format.split('.');
        return format.shift()
    }

    const payOpen = () => {
        setPayModal(true);
    }

    const payClose = () => {
        setPayModal(false);
        setIsLoaded(false);
    }

    const onSubmitForm = event => {
        event.preventDefault()

        setIsLoaded(true);

        const data = {
            path: 'pay/deposit',
            school_id: props.auth.school_id,
            client_id: props.auth.client_id,
            amount: event.target[0].value
        };

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;

            if (response.status === 'done') {
                window.location.replace(response.url)
            }
        })
    }

    const PayModal = ({visible = false}) => {
        if (!visible) return null;

        return (
            <div className="modal position-fixed">
                <div className="modal-dialog text-center">
                    <div className="modal-body">
                        <div className="text-bold">Пополнение баланса</div>
                        <div className="text-gray small">Введите сумму, на которую хотите пополнить баланс, и нажмите "Оплатить"</div>

                        { isLoaded === false ? (
                            <form className="payinfo" onSubmit={ onSubmitForm }>
                                <input type="number" className="form-control mt-3 mb-3" name="amount" min="1" step="1" required />
                                <button type="submit" className="btn btn-success">Оплатить</button>
                            </form>
                        ) : (
                            <div className="p-3">
                                <div className="loader">
                                    <div className="circle"></div>
                                    <div className="circle"></div>
                                    <div className="circle"></div>
                                    <div className="circle"></div>
                                    <div className="circle"></div>
                                </div>
                                <div className="text-sm text-bold">Вы будете перенаправлены в платежную систему!</div>
                            </div>
                        ) }

                        <div className="btn btn-primary w-100 mt-3" onClick={ () => payClose() }>Закрыть</div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        isLoaded === false && isPayInfo ? (
            <React.Fragment>
                { !isPayInfo.expired ? (
                    <div className="balance-block d-flex align-items-center">
                        <img src="/dist/img/balance.svg" className="mr-3" alt="" />
                        <div className="w-100 d-flex justify-content-between align-items-center">
                            <div className="d-block">
                                <div className="balance-header">Ваш баланс</div>
                                <div className="balance-amount">{ moneyFormat(isBalanceMoney + isBalanceBonus) } руб.</div>
                                <div className="balance-bonus">Из них бонусных: { moneyFormat(isBalanceBonus) } руб.</div>
                            </div>
                            <div className="balance-button" onClick={ () => payOpen() }>Пополнить</div>
                        </div>
                    </div>
                ) : (
                    <div className="balance-block balance-expired d-flex align-items-center">
                        <img src="/dist/img/balance-expired.svg" className="mr-3" alt="" />
                        <div className="w-100 d-flex justify-content-between align-items-center">
                            <div className="d-block">
                                <div className="balance-header">Обнаружена задолженность</div>
                                <div className="balance-amount">{ moneyFormat(isPayInfo.nextAmount) } руб.</div>
                            </div>
                            <div className="balance-button" onClick={ () => payOpen() }>Пополнить</div>
                        </div>
                    </div>
                ) }

                <PayModal visible={ isPayModal }/>
            </React.Fragment>
        ) : (
            <div className="content">
                <div className="loader">
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                </div>
            </div>
        )
    )
}

export default Balance;
